<template>
  <b-modal
      id="bv-modal-post-help"
      size="lg"
      hide-footer
  >
    <template #modal-title>
      Speciális jelölések a szövegben
    </template>
    <div class="d-block">
      <div class="row">
        <div class="col-10">
          <h2>Kép beillesztése</h2>
          <h4>[foto,"kép neve","képaláírás","méret","igazítás"]</h4>
          <p>
            <b>kép neve:</b> a cikkíró számára szolgál azonosításképp<br>
            <b>képaláírás:</b> a kép alatt megjelenő szöveg<br>
            <b>méret:</b> a kép mérete, lehetséges értékek: kicsi (s), normál (n), nagy (b), extra (e)<br>
            <b>igazítás:</b> lehetséges értékek: balra (l), középre (m), jobbra (r)
          </p>
        </div>

        <div class="col-10">
          <h2>Fájl beillesztése</h2>
          <h4>[file,"file neve"]Ez a hivatkozás[-file]</h4>
          <p>
            <b>file neve:</b> ezen a néven fogja elmenteni a böngészö az olvasónál
          </p>
        </div>

        <div class="col-10">
          <h2>Szövegdoboz beillesztése</h2>
          <h4>[doboz,"szín","méret","igazítás"]</h4>
          <p>
            <b>[-doboz]</b><br>
            <b>szín:</b> lehetséges értékek: sárga, zöld, piros<br>
            <b>méret:</b> a doboz szélessége %-ban, vagy pixelben<br>
            <b>igazítás:</b> lehetséges értékek: balra, középre, jobbra
          </p>
        </div>

        <div class="col-10">
          <h2>Szövegformázás</h2>

          <p>
            <b>[u]:</b> új sor<br>
            <b>[f]:</b> félkövér betűkiemelés<br>
            <b>[-f]:</b> félkövér betűkiemelés vége<br>
            <b>[a]:</b> aláhúzott betűkiemelés<br>
            <b>[-a]:</b> aláhúzott betűkiemelés vége<br>
            <b>[d]:</b> dőlt betűkiemelés<br>
            <b>[-d]:</b> dőlt betűkiemelés vége<br>
            <br><br>
            <b>Ezek kombinálhatók is:</b><br>
            [f][d]félkövér dőlt betűk jelenekk meg itt[-d][-f]
          </p>
        </div>

        <div class="col-10">
          <h2>Hiperhivatkozás beillesztése</h2>
          <h4>[h,"URL cím","cél"]ez a hivatkozás[-h]</h4>

          <p>
            <b>URL cím: ide mutat a hiperhivatkozás</b> lehetséges értékek: sárga, zöld, piros<br>
            <b>cél:</b>hova töltse a böngésző, ha rákkattintunk. Tetszőleges cél megadható, de vannak speciális értékek is: _blank - mindg egy új ablakot nyit,<br>
            <b>_top:</b> jelenlegi ablak.
          </p>
        </div>

        <div class="col-10">
          <h2>Videó beillesztése</h2>
          <h4>[video,"videó neve"]</h4>

          <p>
            <b>videó neve:</b> ezen a néven fogja elmenteni a böngészö az olvasónál
          </p>
        </div>

        <div class="col-10">
          <h2>Idézőjelek használata</h2>

          <p>
            Az idézőjelekkel határolt szövegekben nem szerepelhet önmagában egy idézőjel ("), mert az a feldolgozást lehetetlenné teszi. Ezért abban az esetben, ha az idézőjelekkel körülvett szövegben idézőjelet kívánunk használni, akkor az a \" formában kell írni.
          </p>
        </div>

      </div>
    </div>
    <b-button class="mt-1 float-right" @click="$bvModal.hide('bv-modal-post-help')">
      Bezárás
    </b-button>
  </b-modal>
</template>

<script>
import {
  BModal, BButton,
} from 'bootstrap-vue'

export default {
  name: "postHelpModal",
  components: {
    BModal,
    BButton,
  }
}
</script>
